import { off } from 'process'

export class Contact {
  id: Number | undefined = undefined
  domainId: Number | undefined = undefined
  contactNo: Number | undefined = undefined

  constructor(id: Number) {
    this.id = id
  }
}

export type BaseAddressType = {
  id: Number | undefined
  languageId: Number
  salutation: String
  name1: String
  name2: String
  attention: String
  address1: String
  address2: String
  zip: String
  locality: String
  country: String
  email: String
  phone1: String
  phone1Hint: String
  phone2: String
  phone2Hint: String
  phone3: String
  phone3Hint: String
}

export class BaseAddress<BaseAddressType> {
  id: Number | undefined = undefined
  languageId: Number = 1
  salutation: String = ''
  name1: String = ''
  name2: String = ''
  attention: String = ''
  address1: String = ''
  address2: String = ''
  zip: String = ''
  locality: String = ''
  country: String = 'CH'
  email: String = ''
  phone1: String = ''
  phone1Hint: String = ''
  phone2: String = ''
  phone2Hint: String = ''
  phone3: String = ''
  phone3Hint: String = ''
  constructor(adr: any) {
    if (adr?.id) {
      this.id = adr.id
    }
    if (adr?.languageId) {
      this.languageId = adr.languageId
    }
    if (adr?.salutation) {
      this.salutation = adr.salutation
    }
    if (adr?.name1) {
      this.name1 = adr.name1
    }
    if (adr?.name2) {
      this.name2 = adr.name2
    }
    if (adr?.attention) {
      this.attention = adr.attention
    }
    if (adr?.address1) {
      this.address1 = adr.address1
    }
    if (adr?.address2) {
      this.address2 = adr.address2
    }
    if (adr?.zip) {
      this.zip = adr.zip
    }
    if (adr?.locality) {
      this.locality = adr.locality
    }
    if (adr?.country) {
      this.country = adr.country
    }
    if (adr?.email) {
      this.email = adr.email
    }
    if (adr?.phone1) {
      this.phone1 = adr.phone1
    }
    if (adr?.phone1Hint) {
      this.phone1Hint = adr.phone1Hint
    }
    if (adr?.phone2) {
      this.phone2 = adr.phone2
    }
    if (adr?.phone2Hint) {
      this.phone2Hint = adr.phone2Hint
    }
    if (adr?.phone3) {
      this.phone3 = adr.phone3
    }
    if (adr?.phone3Hint) {
      this.phone3Hint = adr.phone3Hint
    }
  }
}

export class ContactAddress extends BaseAddress {
  addressId: Number | undefined = undefined
  contactId: Number | undefined = undefined
  contactAddressType: String = 'NO_TYPE'
  domainId: Number | undefined = undefined
  isDefault: Boolean = false
  isDefaultEncashment: Boolean = false

  constructor() {
    super()
    /*
    this.contactAddressType = 'NO_TYPE'
    this.languageId=1
    this.country = 'CH'
    */
  }
}

export class ContactAttribute {
  id: Number = 0
  constructor(id: Number) {
    this.id = id
  }
}

export class ContactAttributeValue {
  id: Number | undefined = undefined
  contact: Contact = new Contact(0)
  contactAttribute: ContactAttribute = new ContactAttribute(0)
  value: String | Number = ''
  constructor(id: Number | undefined) {
    this.id = id
  }
}
