<template>
  <v-container v-if="address.zip">
    <h6 v-if="!hideTitle" class="no-margin-bottom" v-html="$t('masterdata_legal_entities_address_preview')"></h6>
    <div>{{ address.salutation }}</div>
    <div>{{ address.name1 }} {{ address.name2 }}</div>
    <div>{{ address.attention }}</div>
    <div>{{ address.address1 }}</div>
    <div>{{ address.address2 }}</div>
    <div>
      {{ address.zip }}
      <span>{{ address.locality }}</span>
    </div>
    <div v-if="address.country !== 'CH'">{{ getCountryByName(address.country) }}</div>
    <div v-if="showPhoneNumber">{{ address.phone1 }}</div>
    <div v-if="showPhoneNumber">{{ address.phone2 }}</div>
  </v-container>
</template>

<script>
import { BaseAddress } from '@/views/contacts/contact'
import { useCountriesStore } from '@/store/enums/CountriesStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'addressPreview',
  props: {
    address: {
      type: BaseAddress,
      required: false,
      default: () => new BaseAddress()
    },
    showPhoneNumber: { type: Boolean, required: false, default: false },
    hideTitle: { type: Boolean, required: false, default: false }
  },
  data() {
    return {}
  },
  computed: {
    countries() {
      return useCountriesStore().Countries
    }
  },
  methods: {
    getCountryByName(countryName) {
      return this.countries.find((x) => x.name === countryName)[this.$route.params.langKey]
    }
  },
  beforeMount() {
    useCountriesStore().fetchCountries()
  }
})
</script>
